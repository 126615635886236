import React from "react";
import "./header.css";

const Header = () => {
  return (
    <div className="Header">
      <div class="message-container">
        <div class="message">
          Check out our facebook and google page for seasonal promotions!
        </div>
        <div class="message">
          Seniors (65+) and College Students get 20% off of regular price
        </div>
        <div class="message">Thank you for your support!</div>
      </div>
      <div className="Header-content">
        <img src="/logo.png" alt="logo"></img>

        <p>
          <h1>Shirley Tan</h1>
          <h1>LMT #139596</h1>
          <h2>Text/Call 469-988-9039</h2>
          <a href="https://www.massagebook.com/therapists/shirley-massage-therapy">
            <button className="Header-button">Book Now</button>
          </a>
        </p>
      </div>
      <div className="Header-about">
        <h2>About</h2>
        <p>
          Shirley Massage Therapy is a practice located in Plano, TX. We
          specialize in therapeutic Swedish massage, lymphatic drainage, muscle
          energy technique. We believe that massage is a powerful tool for
          relaxation, pain relief, and overall well-being.
        </p>
        <p>
          Our therapist(s) are experienced and certified massage therapist(s)
          who are passionate about helping people feel their best. We take time
          to know each of our clients so that we can provide them with the most
          personalized and effective massage possible.
        </p>
      </div>
      <div className="Header-reviews">
        <h2>What Our Customers Say</h2>
        <iframe
          src="https://www.massagebook.com/therapists/shirley-massage-therapy/widget/reviews"
          frameborder="0"
          width="85%"
          height="1000"
        ></iframe>
      </div>
    </div>
  );
};

export default Header;
