"use client";
import Navbar from "./components/navbar/Navbar";
import Services from "./components/services/Services";
import Contact from "./components/contact/Contact";
import Header from "./containers/header/Header";
import About from "./components/about/About";
import Incentives from "./components/incentives/Incentives";
import Footer from "./containers/footer/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

const App = () => {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Header />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/incentives" element={<Incentives />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
