import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="Footer-contact_container">
        <div className="Footer-contact">
          <h3>ACM Health Solutions</h3>
          <p>3900 Pebblecreek Ct</p>
          <p>Suite 101</p>
          <p>Plano TX, 75023</p>
          <p>United States</p>
        </div>
        <div className="Footer-contact">
          <p>shirleylmt9596@gmail.com</p>

          <p>469-988-9039</p>
        </div>
      </div>

      <div className="Footer-social">
        <Link to="https://posts.gle/xwoLu1">
          <img src="/google_logo.png" alt="error"></img>
        </Link>
        <Link to="https://www.facebook.com/profile.php?id=61555562977264">
          <img src="/facebook_logo.png" alt="error"></img>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
