import React, { useRef } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    const first_name = formData.get("first_name");
    const last_name = formData.get("last_name");
    const email = formData.get("email");
    const message = formData.get("message");

    if (!email || !message || !first_name || !last_name) {
      alert("Please fill in all the required fields.");
      return;
    }

    emailjs
      .sendForm("service_f3v1amq", "template_amcm92d", form.current, {
        publicKey: "otMPnL8Rqb7jboeaH",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          alert("Message sent!");
        },
        (error) => {
          console.log("FAILED", error.text);
          alert("Message failed to send.");
        }
      );
  };

  return (
    <div className="Contact">
      <h1>Contact Information</h1>

      <div className="Contact-content">
        <div className="Contact-desc-container">
          <div className="Contact-desc">
            <h2>Email</h2>
            <p>shirleylmt9596@gmail.com</p>
          </div>
          <div className="Contact-desc">
            <h2>Phone</h2>
            <p>469-988-9039</p>
          </div>
          <div className="Contact-desc">
            <h2>Location</h2>
            <div>
              <div className="Contact-address">
                <h3>ACM Health Solutions</h3>
                <p>3900 Pebblecreek Ct</p>
                <p>Suite 101</p>
                <p>Plano TX, 75023</p>
                <p>United States</p>
              </div>
            </div>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3344.1035383691483!2d-96.77178902408103!3d33.05374087354933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c22bfc3a1feab%3A0x13a50528b5746133!2s3900%20Pebblecreek%20Ct%20%23101%2C%20Plano%2C%20TX%2075023!5e0!3m2!1sen!2sus!4v1736303388220!5m2!1sen!2sus"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        <div className="Contact-form">
          <h2>Questions?</h2>

          <form ref={form} onSubmit={sendEmail}>
            <div className="Contact-form_info">
              <div className="Contact-form_input">
                <h3>Name</h3>
                <div className="Contact-form_name">
                  <input
                    className="Contact-input"
                    type="text"
                    name="first_name"
                    placeholder="First"
                  />
                  <input
                    className="Contact-input"
                    type="text"
                    name="last_name"
                    placeholder="Last"
                  ></input>
                </div>
              </div>
              <div className="Contact-form_input">
                <h3>Email</h3>
                <input
                  className="Contact-input_email"
                  type="email"
                  name="email"
                  placeholder=""
                ></input>
              </div>
              <div className="Contact-form_input">
                <h3>Comment</h3>
                <textarea name="message" placeholder=""></textarea>
              </div>
              <div className="Contact-form_input">
                <input
                  className="Contact-form_button"
                  type="submit"
                  value="Submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
